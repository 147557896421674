var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.$t('client.client'),"no-close-on-backdrop":"","hide-header-close":_vm.loading,"no-close-on-esc":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('validation-observer',{ref:"validation"},[_c('b-form-group',{attrs:{"label":_vm.$t('general.name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.name),callback:function ($$v) {_vm.$set(_vm.object, "name", $$v)},expression:"object.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.address')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.address),callback:function ($$v) {_vm.$set(_vm.object, "address", $$v)},expression:"object.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.alias')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.alias'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.alias),callback:function ($$v) {_vm.$set(_vm.object, "alias", $$v)},expression:"object.alias"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.tax_number')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.tax_number'),"rules":"required|numeric|min:8|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.object.tax_number),callback:function ($$v) {_vm.$set(_vm.object, "tax_number", $$v)},expression:"object.tax_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.closing_time')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.closing_time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{model:{value:(_vm.object.closing_time),callback:function ($$v) {_vm.$set(_vm.object, "closing_time", $$v)},expression:"object.closing_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.tier')}},[_c('validation-provider',{attrs:{"name":_vm.$t('client.tier'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (ele) { return ele.id; },"clearable":false,"options":_vm.tiers,"appendToBody":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('table.no_data_to_display'))+" ")]},proxy:true}],null,true),model:{value:(_vm.object.tier),callback:function ($$v) {_vm.$set(_vm.object, "tier", $$v)},expression:"object.tier"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('client.is_tax_payer')}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.is_tax_payer),callback:function ($$v) {_vm.$set(_vm.object, "is_tax_payer", $$v)},expression:"object.is_tax_payer"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('general.disabled')}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.object.disabled),callback:function ($$v) {_vm.$set(_vm.object, "disabled", $$v)},expression:"object.disabled"}})],1)],1)],1)]},proxy:true},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.validate}},[(_vm.editing)?_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.edit')))])],1):_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('general.add')))])],1),(_vm.loading)?_c('b-spinner',{staticClass:"ml-1",staticStyle:{"height":"15px","width":"15px"}}):_vm._e()],1)]},proxy:true}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}})}
var staticRenderFns = []

export { render, staticRenderFns }