<template>
    <b-modal :title="$t('client.client')" v-model="modalActive" no-close-on-backdrop :hide-header-close="loading" :no-close-on-esc="loading">
        <template #default>
            <b-overlay :show="loading">
                <validation-observer ref="validation">
                    <b-form-group :label="$t('general.name')">
                        <validation-provider #default="{ errors }" :name="$t('general.name')" rules="required">
                            <b-form-input v-model="object.name"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('client.address')">
                        <validation-provider #default="{ errors }" :name="$t('client.address')" rules="required">
                            <b-form-input v-model="object.address"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('client.alias')">
                        <validation-provider #default="{ errors }" :name="$t('client.alias')" rules="required">
                            <b-form-input v-model="object.alias"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('client.tax_number')">
                        <validation-provider #default="{ errors }" :name="$t('client.tax_number')" rules="required|numeric|min:8|max:10">
                            <b-form-input v-model="object.tax_number"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('client.closing_time')">
                        <validation-provider #default="{ errors }" :name="$t('client.closing_time')" rules="required">
                            <b-form-timepicker v-model="object.closing_time"/>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('client.tier')">
                        <validation-provider #default="{ errors }" :name="$t('client.tier')" rules="required">
                            <v-select v-model="object.tier" :reduce="ele => ele.id" :clearable="false" :options="tiers" appendToBody>
                                <template #no-options>
                                    {{$t('table.no_data_to_display')}}
                                </template>
                            </v-select>
                            <small class="text-danger">{{errors[0]}}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group :label="$t('client.is_tax_payer')">
                        <b-form-checkbox switch v-model="object.is_tax_payer"/>
                    </b-form-group>
                    <b-form-group :label="$t('general.disabled')">
                        <b-form-checkbox switch v-model="object.disabled"/>
                    </b-form-group>
                </validation-observer>
            </b-overlay>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate" :disabled="loading">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{$t('general.edit')}}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{$t('general.add')}}</span>
                </span>
                <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"/>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal, BFormCheckbox, BFormTimepicker, BSpinner, BOverlay} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, min, max, numeric } from '@validations'
    import {Tiers} from '@/libs/enums/Tiers'
    import vSelect from 'vue-select'
    import {captureException} from '@sentry/vue'

    export default {
        components: {
            BOverlay,
            BSpinner,
            vSelect,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            BFormTimepicker,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                loading: false,
                tiers: [
                    {
                        label: 'Essential',
                        id: Tiers.Essential
                    },
                    {
                        label: 'Standard',
                        id: Tiers.Standard
                    },
                    {
                        label: 'Premium',
                        id: Tiers.Premium
                    }
                ],
                modalActive: false,
                object: {
                    name: '',
                    address: '',
                    alias: '',
                    tax_number: '',
                    closing_time: '04:00:00',
                    is_tax_payer: false,
                    disabled: false,
                    tier: Tiers.Essential
                },
                editing: false,
                required,
                min,
                max,
                numeric
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.object = JSON.parse(JSON.stringify(data))
                    this.editing = true
                    this.object.closing_time = this.$dayjs(this.object.closing_time).utc().format('HH:mm')
                } else {
                    this.editing = false
                    this.object =  {
                        name: '',
                        address: '',
                        alias: '',
                        tax_number: null,
                        closing_time: '04:00:00',
                        is_tax_payer: false,
                        disabled: false,
                        tier: Tiers.Essential
                    }
                }
                this.loading = false
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            async add() {
                try {
                    this.loading = true
                    const timestampString = `1970-01-31T${this.object.closing_time}.000Z`
                    const timestamp = this.$dayjs(timestampString)

                    const tmpObject = {
                        name: this.object.name,
                        address: this.object.address,
                        alias: this.object.alias,
                        closing_time: timestamp,
                        tax_number: Number(this.object.tax_number),
                        is_tax_payer: this.object.is_tax_payer,
                        disabled: this.object.disabled,
                        tier: this.object.tier
                    }
                    const response = await this.$http.post('/api/reseller/v1/client/', tmpObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('success.on_add'))
                    this.object = {
                        name: '',
                        address: '',
                        closing_time: '04:00:00',
                        user_ids: [],
                        tier: Tiers.Essential
                    }
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_add'))
                } finally {
                    this.loading = false
                }
            },
            async edit() {
                try {
                    this.loading = true
                    const timestampString = `1970-01-31T${this.object.closing_time}.000Z`
                    const timestamp = this.$dayjs(timestampString)

                    const tmpObject = {
                        name: this.object.name,
                        address: this.object.address,
                        alias: this.object.alias,
                        tax_number: Number(this.object.tax_number),
                        closing_time: timestamp,
                        is_tax_payer: this.object.is_tax_payer,
                        disabled: this.object.disabled,
                        tier: this.object.tier
                    }

                    const response = await this.$http.put(`/api/reseller/v1/client/${this.object.id}`, tmpObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('success.on_edit'))
                    this.object = {
                        name: '',
                        address: '',
                        closing_time: '04:00:00',
                        user_ids: [],
                        version: Tiers.Essential
                    }
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_edit'))
                } finally {
                    this.loading = false
                }
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
