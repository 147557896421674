<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('general.clients')">
            <b-row>
                <b-col class="text-right">
                    <b-button variant="primary" @click="$refs.modal.open()">
                        <feather-icon icon="PlusSquareIcon"/>
                        {{$t('client.add_client')}}
                    </b-button>
                </b-col>
            </b-row>
            <basic-table :columns="columns" :data="clients" v-slot="props" :enableRowMarking="true">
                <span v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                    <b-button variant="warning" class="mr-0" @click="editClient(props.row.id)"><feather-icon icon="EditIcon"/></b-button>
                </span>
                <span v-else-if="props.column.displayType === 3" class="d-flex justify-content-center">
                    <span v-if="props.row.tier === Versions.Essential" style="color: #92d694">
                        Essential
                    </span>
                    <span v-else-if="props.row.tier === Versions.Standard" style="color: #feb28d">
                        Standard
                    </span>
                    <span v-else-if="props.row.tier === Versions.Premium" style="color: #ed603b">
                        Premium
                    </span>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>
        </b-card>

        <AddClient ref="modal" v-on:itemAdded="loadData"/>
    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import AddClient from '@/views/Clients/AddClient'
    import {BButton, BCard, BCol, BOverlay, BRow} from 'bootstrap-vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import {captureException} from '@sentry/vue'

    export default {
        computed: {
            Versions() {
                return Tiers
            }
        },
        components: {BasicTable, AddClient, BRow, BCol, BCard, BOverlay, BButton},
        data() {
            return {
                clients: [],
                columns: [
                    {
                        label: this.$t('general.name'),
                        field: 'name',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('client.alias'),
                        field: 'alias',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('client.address'),
                        field: 'address',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('client.tier'),
                        field: 'tier',
                        displayType: 3,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Essential', 'Standard', 'Premium'],
                            filterFn: this.versionFilter
                        }
                    },
                    {
                        label: this.$t('table.options'),
                        field: 'options',
                        displayType: 2,
                        filterOptions: {
                            enabled: false
                        },
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            editClient(client_id) {
                this.$router.push({name: 'edit_client', params: { client_id}})
            },
            versionFilter(data, filterString) {
                if (filterString === 'Essential' && data === Tiers.Essential) {
                    return true
                } else if (filterString === 'Standard' && data === Tiers.Standard) {
                    return true
                } else if (filterString === 'Premium' && data === Tiers.Premium) {
                    return true
                } 

                return false
            },
            async loadData() {
                try {
                    this.showLoader = true
                    const response = await this.$http.get('/api/reseller/v1/client/')
                    this.clients = response.data ?? []
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async remove(id) {
                const confirmDelete = await this.$confirmDialog(this.$t('table.delete_row_message'))
                if (!confirmDelete) return
              
                try {
                    this.showLoader = true
                    await this.$http.delete(`api/reseller/v1/client/${id}`)
                    this.$printSuccess(this.$t('success.on_delete'))
                    await this.loadData()
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_delete'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>
</style>
